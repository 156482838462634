<template>
  <div class="title azjg">
    <div v-if="isgxqy">
      <i class="ired" v-if="isRequired">* </i>
      <span>区域名称：</span>
      <el-select ref="selectArea" @visible-change="$visibleChange($event, 'selectArea')" v-model="cgxqy" placeholder="全部" @change="cxjgmc" clearable filterable>
        <el-option v-for="(i, n) in cgxqyArr" :key="n" :value="i.id" :label="i.name"></el-option>
      </el-select>
    </div>
    <div v-if="isjgmc">
      <i class="ired" v-if="isRequired">* </i>
      <span>机构名称：</span>
      <el-select ref="selectOrg" @visible-change="$visibleChange($event, 'selectOrg')" v-model="cjgmc" placeholder="全部" @change="getDatas" clearable filterable>
        <el-option v-for="(i, n) in cjgmcArr" :key="n" :value="i.id" :label="i.name"></el-option>
      </el-select>
    </div>
  </div>
</template>
<script>
import { organizationQueryWithPage } from '@/util/http'
export default {
  data() {
    return {
      cgxqy: '', //查询区域名称
      cgxqyArr: JSON.parse(sessionStorage.getItem('gxqylist')), //查询区域名称数组
      cjgmc: '', //查询机构名称
      cjgmcArr: [], //查询机构名称数组
      isgxqy: true, //区域下拉框显示影藏
      isjgmc: true, //机构下拉框显示影藏
      user: this.$store.state.user,
      ids: this.$store.state.deviceIds,
    }
  },
  props: {
    iptType: {
      type: String,
      require: true,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    // 查询机构名称
    async cxjgmc() {
      this.cjgmc = ''
      this.cjgmcArr = []
      let params = {
        useFlag: 0,
        areaId: this.cgxqy,
        page: 1,
        pageSize: 10000,
      }
      await organizationQueryWithPage(params).then((data) => {
        if (data) {
          if (this.cgxqy) {
            this.cjgmcArr = data.result.records
          } else {
            this.cjgmcArr = []
          }
          if (!this.ids.length) {
            this.$emit('setData', [this.cgxqy], this.iptType)
          }
        }
      })
    },
    // 传两个值
    getDatas() {
      this.$emit('setData', [this.cgxqy, this.cjgmc], this.iptType)
    },
    // 初始化
    async init() {
      this.cgxqy = this.user.areaId
      this.cjgmc = this.user.organizationId
      if (this.cjgmc) {
        this.$emit('setData', [this.cgxqy, this.cjgmc], this.iptType)
      }
      if (this.cgxqy && !this.cjgmc) {
        await this.cxjgmc()
      }
    },
  },
  mounted() {
    this.isgxqy = !this.user.areaId
    this.isjgmc = !this.user.organizationId
    if (this.ids.length) {
      this.cgxqy = this.ids[0]
      this.cxjgmc()
      this.cjgmc = this.ids[1]
      this.getDatas()
    } else {
      this.init()
    }
    // 清除下拉框
    this.$bus.$on('clickResert', (data) => {
      if (data == this.iptType) {
        this.cjgmcArr = []
        this.init()
      }
    })
  },
  beforeDestroy() {
    this.$bus.$off('clickResert')
  },
}
</script>
<style src="../../../public/css/search.less" lang="less" scoped></style>
